/* .app__page-container{} */
.app__page-top {
  max-width: 1400px;
  padding: 25px 50px 0 50px;
  margin: 0 auto;
}
.app__page-interior {
  max-width: 1400px;
  padding: 0 50px 0 50px;
  margin: 0 auto;
}
.app__page-bottom {
  max-width: 1400px;
  padding: 75px 50px 100px;
  margin: 0 auto;
}
.app__text_underline {
  text-decoration: underline;
}
.app__text_bold {
  -webkit-text-stroke-width: 0.8px;
}

@media screen and (max-width: 500px) {
  .app__page-top,
  .app__page-interior {
    padding: 25px 25px 0;
  }
  .app__page-bottom {
    padding: 75px 25px 100px;
  }
}
