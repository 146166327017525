/* .gallery {} */
.gallery__header {
  background-color: rgb(168, 168, 168);
  padding: 50px 25px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.gallery__title {
  font-size: 40px;
}
.gallery__text {
  font-size: 20px;
  margin: 0 auto;
  padding: 0 100px 10px;
}
.gallery__text:nth-child(4) {
  color: rgb(90, 90, 90);
}
.gallery__image-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0px 50px;
  font-size: 30px;
}
.gallery__image-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 30px;
}
.gallery__image-item {
  width: 320px;
  height: 350px;
}
.gallery__image {
  width: 100%;
  height: 100%;
  border: solid;
  border-radius: 10px;
  border-color: rgb(41, 41, 41);
}
.gallery__image:hover {
  cursor: pointer;
  opacity: 90%;
  transition: 0.3s ease-out;
}

@media screen and (max-width: 800px) {
  .gallery__image-item {
    width: 125px;
    height: 125px;
  }
}

@media screen and (max-width: 600px) {
  .gallery__text {
    padding: 0 0 10px 0;
  }
}

@media screen and (max-width: 350px) {
  .gallery__image-list {
    grid-gap: 10px;
  }
  .gallery__image-item {
    width: 100px;
    height: 100px;
  }
}
