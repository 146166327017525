/* .footer {} */
.footer__container {
  display: flex;
  justify-content: space-between;
}
/* .footer__text {} */
.footer__image {
  width: 25px;
  border-radius: 5px;
  cursor: help;
}
.footer__image:hover {
  opacity: 60%;
}

@media screen and (max-width: 600px) {
  .footer__container {
    flex-direction: column;
    align-items: center;
  }
  .footer__text {
    text-align: center;
  }
}
