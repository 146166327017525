/* .services {} */
.services__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.services__title {
  margin: 50px 0px 50px;
  font-size: 40px;
}
.services__text {
  text-align: center;
  margin: 0;
}
/* .services__container {} */
.services__list {
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  grid-gap: 20px 20px;
  padding: 0;
  margin: 50px 0px 50px;
  justify-content: center;
}
.services__item {
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  border-radius: 5%;
  flex: 0 0 325px; /* Set flex-basis to 350px for fixed width */
  transition: transform 0.3s ease;
}
.services__item:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.services__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.services__footer-text {
  margin: 0;
}
.services__footer-text:nth-child(2) {
  margin: 15px 0 50px 0;
}
.services__footer-link {
  font-family: "Roboto-Bold-Italic", "Lato-Bold-Italic", sans-serif;
  font-size: 20px;
  padding: 10px;
  background-color: white;
  text-decoration: none;
  color: black;
  border-color: black;
  border-radius: 15px;
  border-style: groove;
  transition: 0.5s ease-out;
}
.services__footer-link:hover {
  cursor: pointer;
  background-color: rgb(228, 228, 228);
}
/* ipad mini users eating good with the dual cards */
@media screen and (max-width: 850px) {
  .services__item {
    flex: 0 0 300px;
  }
}

@media screen and (max-width: 500px) {
  .services__item {
    flex: none;
    width: calc(100% - 20px);
  }
}
