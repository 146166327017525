.navigation {
  position: relative;
}
.navigation__container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* In case you change the screen size */
  height: 60px;
  width: 100%;
  padding: 0.5rem 0rem;
}
.navigation__logo {
  max-height: 50px;
}
.navigation__navmobile {
  position: absolute;
  right: 0px;
  cursor: pointer;
  display: inline-block;
}
/* X Functionality */
.navmobile__container {
  display: inline-block;
}
.navmobile__container:hover {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}
.navmobile__line {
  width: 50px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: transform 0.5s ease-in-out;
}
.navmobile__container_active .navmobile__line:nth-child(1) {
  transform: translate(0, 11px) rotate(-45deg);
}
.navmobile__container_active .navmobile__line:nth-child(2) {
  transform: scaleX(0);
}
.navmobile__container_active .navmobile__line:nth-child(3) {
  transform: translate(0, -11px) rotate(45deg);
}
.navigation__navlink {
  text-decoration: none;
}
/* Menu */
.navigation__links {
  display: flex;
  padding: 0;
  margin-top: 15px;
  list-style: none;
  padding: 0;
  color: #333;
  font-size: 20px;
}
.navigation__link {
  margin: 0 1rem;
  text-decoration: none;
  width: 100%;
  display: block;
  color: black;
  background-color: white;
  transition: 0.5s ease-out;
  border-style: none;
  padding: 0;
}
.navigation__link:hover {
  transition: 0.2s ease-in;
  color: rgb(62, 130, 255);
  text-decoration: underline;
}

@media screen and (min-width: 1001px) {
  /* Hide mobile*/
  .navigation__navmobile {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  /* Display mobile menu and hide other*/
  .navigation__navmobile {
    display: block;
  }

  .navigation__links {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: white;
    height: 0px;
    transition: height 0.5s ease; /* after closing */
    overflow: hidden; /* Hide overflow content */
  }

  .navigation__link {
    text-align: center;
    margin: 0;
    color: black;
    width: 100%;
    padding: 1.5rem 0;
    background-color: #dddddd;
    transition: 1sec;
  }

  .navigation__link:hover {
    color: black;
    background-color: rgb(93, 149, 255);
  }

  /* visabillaty at last for links */
  /* if another link is added, height needs to be altered*/
  .navigation__links_active {
    border-top: 0.1px solid black;
    height: 210px;
    transition: height 0.5s ease; /* after opening */
  }
}

@media screen and (max-width: 500px) {
  .navigation__logo {
    max-height: 35px;
  }
}
@media screen and (max-width: 300px) {
  .navigation__logo {
    max-height: 30px;
  }
}
