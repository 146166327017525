.header {
  width: 100%;
  background-image: url(../../images/exterior__1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.header__container {
  max-width: 1400px;
  padding: 0 50px 25px 50px;
  margin: 0 auto;
  -webkit-text-stroke: 0.8px black;
  color: white;
}
.header__content {
  padding: 100px 0 100px 0;
  margin: 0;
}
.header__title {
  font-size: 50px;
}
.header__text {
  max-width: 600px;
  font-size: 25px;
}
.header__background-image {
  width: 100%; /* Ensure the image stretches across the screen */
  position: absolute;
  left: 50%;
  height: 155px;
  top: 100px;
}
.header__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__link {
  text-decoration: none;
  color: black;
  border-color: black;
  font-size: 20px;
  transition: 0.5s ease-out;
  padding: 10px;
  border-radius: 15px;
  border-style: groove;
}
.header__link:hover {
  cursor: pointer;
  opacity: 90%;
  transition: 0.2s ease-in;
  border-color: black;
  color: red;
  background-color: #c9c9c9;
}
/* .header__location {} */

@media screen and (max-width: 700px) {
  .header__logo {
    width: 150px;
  }
  .header__link,
  .header__location {
    font-size: 15px;
  }
}
@media screen and (max-width: 500px) {
  .header__container {
    padding: 25px;
  }
  .header__footer {
    flex-direction: column;
  }
}
