.itemModal {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* transition: 3s ease-in-out; */
}
.itemModal__container {
  position: relative;
}
.itemModal__closeLogo {
  width: 50px;
  position: absolute;
  right: -20px;
  top: -50px;
}
.itemModal__closeLogo:hover {
  cursor: pointer;
  opacity: 80%;
  transition: 0.3s ease-out;
}
.itemModal__image {
  max-width: 85vw; /* Adjusts image to screen and leaves space */
  max-height: 75vh;
  padding: 0 30px 0;
}
.itemModal__description {
  color: white;
  padding: 0 30px 0;
  margin: 0;
}

@media screen and (max-width: 950px) {
  .itemModal__closeLogo {
    right: 30px;
    top: -60px;
  }
}
